import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { SplitText } from "gsap/SplitText";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { defaults } from "autoprefixer";
window.gsap = gsap;
window.MorphSVGPlugin = MorphSVGPlugin;
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin, SplitText, DrawSVGPlugin);

ScrollTrigger.defaults({
  toggleActions: "restart complete resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

//------------------------------------------------------//
// FAQ toggle 
//------------------------------------------------------//
const containerFaq = document.querySelectorAll('.overview-faq');

containerFaq.forEach(container => {
  const items = container.querySelectorAll('.alloy-item');
  items.forEach(item => {
    // The animation 
    const tl = gsap.timeline({ defaults: { duration: 0.3 }, reversed: true, paused: true })
    tl.set(item.querySelector('.content'), { autoAlpha: 1 })
    tl.to(item.querySelector('.content'), { height: "auto", })
    tl.to(item.querySelector('.icon'), { rotate: 180 }, "<")

    // The event listener
    item.addEventListener('click', e => {
      tl.reversed() ? tl.play() : tl.reverse();
    })
  });
});

// END FAQ toggle -------------------------------------//
//--------------------------------//
// Default animation whole site
//--------------------------------//
function cardAnimation(container, item) {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=20%", // Can be top, center, bottom 
      end: "+=150px bottom-=30%", // Can be top, center, bottom 
    }
  })
  tl.from(item, {
    // duration: 0.2,
    ease: "none",
    y: 100,
    opacity: 0,
    stagger: {
      each: 0.1,
      ease: "none"
    }
  })
}
// ? Below all these elements have the same animation based on the function above
document.querySelectorAll('.ACF-cards').forEach(container => {
  cardAnimation(container, container.querySelectorAll('.alloy-card'))
})
document.querySelectorAll('.ACF-snel_menu').forEach(container => {
  cardAnimation(container, container.querySelectorAll('.alloy-card'))
})
document.querySelectorAll('.ACF-call_to_action').forEach(container => {
  cardAnimation(container, container.querySelectorAll('.illustation'))
})
document.querySelectorAll('.ACF-split_content').forEach(container => {
  cardAnimation(container, container.querySelectorAll('.alloy-image'))
})
// END Default animation whole site --------------------------------//

//--------------------------------//
// Quote 
//--------------------------------//
document.querySelectorAll(".ACF-quote").forEach(function (container) {

  const split = new SplitText(container.querySelector('blockquote p'), { type: "lines" });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=20%", // Can be top, center, bottom 
      end: "+=150px bottom-=30%", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(split.lines, {
    ease: "none",
    duration: 0.3,
    opacity: 0,
    rotation: 5,
    y: 100,
    stagger: {
      amount: 0.5
    }
  });

});
// END Quote --------------//

//--------------------------------//
// Illustraties 
//--------------------------------//
// document.querySelectorAll(".ACF-call_to_action").forEach(function (container) {

//   // const split = new SplitText(container.querySelector('blockquote p'), { type: "lines" });
//   const item = container.querySelectorAll('svg path');
//   const timeline = gsap.timeline({
//     scrollTrigger: {
//       trigger: container, // What element triggers the scroll
//       scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
//       start: "top bottom-=20%", // Can be top, center, bottom 
//       end: "100% center", // Can be top, center, bottom 
//       // pin: true,
//     }
//   });

//   timeline.from(item, {
//     duration: 0.3,
//     morphSVG: {
//       shape: "M321.5 323C321.5 339.569 305.016 353 289 353C272.984 353 260 339.569 260 323C260 306.431 272.984 293 289 293C305.016 293 321.5 306.431 321.5 323Z",
//       type: "rotational",
//     },
//     stagger: {
//       amount: 1
//     }
//   });

// });
// END Illustraties --------------//

//--------------------------------//
// 404 
//--------------------------------//
if (document.querySelector('.error404 .svg-404')) {
  const strokeLength = 55;
  gsap.timeline({ delay: 0.75 })
    .fromTo('.error404 .mask', {
      drawSVG: 0
    }, {
      duration: 1,
      drawSVG: `0% ${strokeLength}%`,
      ease: "power2.in"
    })
    .to('.error404 .mask', {
      duration: 40,
      drawSVG: `100% 1${strokeLength}%`,
      ease: "none",
      repeat: -1
    });
}
// END 404 --------------//


// //--------------------------------//
// // 404 
// //--------------------------------//
// if (document.querySelector('#navigation-quick .menu-toggle')) {
//   // const strokeLength = 55;
//   const timeline = gsap.timeline({
//     immediateRender: false,
//     scrollTrigger: {
//       trigger: "#navigation-quick", // What element triggers the scroll
//       scrub: false, // Add a small delay of scrolling and animation. `true` is direct
//       start: "bottom top", // Can be top, center, bottom 
//       endTrigger: "footer"
//       // end: "+=150px bottom-=30%", // Can be top, center, bottom 
//       // pin: true,
//     }
//   });
//   timeline.set("#navigation-quick .menu-toggle", {
//     position: "fixed",
//     top: 0,
//     right: 0,
//   })
//   timeline.from("#navigation-quick .menu-toggle", {
//     xPercent: 100,
//   });
// }
// // END 404 --------------//

